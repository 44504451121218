<template>
    <div>
        <vue-title title="Регистрация | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsRoutes"/>
        <div class="container py-4">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Регистрация</h1>
            </div>
            <div class="row">
                <div class="col-12 col-md-2 col-lg-3"></div>
                <div class="col-12 col-md-8 col-lg-6">
                    <div id="form-container">
                        <div v-if="errorMessage" class="alert alert-danger mb-3" role="alert">
                            <strong>Внимание!</strong> {{ errorMessage }}
                        </div>
                        <form class="registration-form" @submit.prevent="onSubmit">
                            <DxForm
                                    id="form"
                                    :col-count="1"
                                    :form-data="formData"
                                    :read-only="false"
                                    :show-colon-after-label="true"
                                    :show-validation-summary="true"
                                    :label-location="labelLocation"
                                    :disabled="isLoading"
                            >
                                <DxColCountByScreen :xs="1" :sm="1"/>
                                <DxSimpleItem
                                        :colSpan="2"
                                        data-field="CompanyBin"
                                        :editor-options="{
                                        placeholder: '',
                                        dataSource: storeСompanyBin,
                                        displayExpr: 'bin',
                                        valueExpr: 'bin',
                                        searchEnabled: true,
                                        showClearButton: true,
                                        onValueChanged: companyBinChanged,
                                        minSearchLength: 3,
                                        acceptCustomValue: true,
                                        onCustomItemCreating: onCustomItemCreating
                                }"
                                        editor-type="dxSelectBox"
                                >
                                    <DxLabel text="БИН компании"/>
                                    <DxRequiredRule message="Укажите БИН компании"/>
                                </DxSimpleItem>
<!--                                <DxSimpleItem data-field="CompanyBin" :colSpan="2">-->
<!--                                    <DxLabel text="БИН компании"/>-->
<!--                                    <DxRequiredRule message="Укажите БИН компании"/>-->
<!--                                </DxSimpleItem>-->
                                <DxSimpleItem data-field="CompanyName" :colSpan="2"
                                              :editor-options="{
                                                    value: CompanyName,
                                                    onValueChanged: handleCompanyNameValueChange
                                            }"
                                >
                                    <DxLabel text="Наименование компании"/>
                                    <DxRequiredRule message="Укажите наименование компании"/>
                                </DxSimpleItem>
                                <DxSimpleItem data-field="AgentName" :colSpan="2">
                                    <DxLabel text="Фамилия Имя"/>
                                    <DxRequiredRule message="Укажите Фамилия Имя"/>
                                </DxSimpleItem>
                                <DxSimpleItem data-field="Email" :colSpan="2">
                                    <DxLabel text="Email"/>
                                    <DxEmailRule message="Неверно указан Email"/>
                                    <DxRequiredRule message="Укажите Email"/>
                                </DxSimpleItem>
                                <DxSimpleItem data-field="Mobile"
                                              :editor-options="mobileEditorOptions"
                                              :colSpan="2"
                                >
                                    <DxLabel text="Моб. телефон"/>
                                    <DxPatternRule
                                            :pattern="phonePattern"
                                            message="Телефон не соответствует формату"
                                    />
                                </DxSimpleItem>
                                <DxSimpleItem data-field="Phone"
                                              :editor-options="phoneEditorOptions"
                                              :colSpan="2"
                                >
                                    <DxLabel text="Раб. телефон"/>
                                    <DxPatternRule
                                            :pattern="phonePattern"
                                            message="Телефон не соответствует формату"
                                    />
                                </DxSimpleItem>
                                <DxSimpleItem data-field="Login" :colSpan="2">
                                    <DxLabel text="Логин"/>
                                    <DxRequiredRule message="Укажите ваш логин"/>
                                </DxSimpleItem>
                                <DxSimpleItem data-field="Password"
                                              :editor-options="passwordOptions"
                                              :colSpan="2"
                                >
                                    <DxLabel text="Пароль"/>
                                    <DxRequiredRule message="Укажите ваш пароль"/>
                                </DxSimpleItem>
                                <DxSimpleItem
                                        :editor-options="checkBoxOptions"
                                        data-field="Accepted"
                                        editor-type="dxCheckBox"
                                        cssClass="mt-2"
                                >
                                    <DxLabel :visible="false"/>
                                    <DxCompareRule
                                            :comparison-target="checkComparison"
                                            type="compare"
                                            message="Подтвердите, что вы согласны с условиями Пользовательского соглашения и Политикой конфиденциальности."
                                    />
                                </DxSimpleItem>
<!--                                <DxButtonItem-->
<!--                                        horizontalAlignment="left"-->
<!--                                        cssClass="pt-3"-->
<!--                                        :button-options="buttonAgreementsPrivacyOptions"-->
<!--                                />-->
<!--                                <DxButtonItem-->
<!--                                        horizontalAlignment="left"-->
<!--                                        cssClass="pt-3"-->
<!--                                        :button-options="buttonAgreementsUserOptions"-->
<!--                                />-->

                                <DxButtonItem
                                        :button-options="buttonOptions"
                                        cssClass="mt-2"
                                        :colSpan="2"
                                />
                            </DxForm>
                        </form>
                    </div>
                    <hr>
                    <div class="text-center">Уже зарегистрированы?
                        <DxButton
                                text="Вход"
                                type="default"
                                styling-mode="outlined"
                                @click="openLogin"
                                class="ml-3"
                        />
                    </div>
                </div>
                <div class="col-12 col-md-2 col-lg-3"></div>
                <div class="col-12 col-md-2"></div>
                <div class="col-12 col-md-8 mt-5 small">
                    <p>
                        * Внимание! Пароль для входа в Личный кабинет открывает доступ ко всей информации по Вашему
                        договору. Пожалуйста, будьте бдительны и никому не сообщайте данный пароль.
                    </p>
                    <p>
                        ** В целях безопасности по истечении 60 минут неактивности сессия работы в Личном кабинете будет
                        автоматически закрыта.<br>
                        Если Вы закончили работу, обязательно корректно выйдите из личного кабинета, нажав кнопку
                        «Выйти».
                    </p>
                    <p>
                        *** Для корректной работы сайта в настройках веб-браузера должна быть разрешена работа с <a
                            href="https://ru.wikipedia.org/wiki/JavaScript" target="_blank"><strong>JavaScript</strong></a>
                        и
                        <a href="https://ru.wikipedia.org/wiki/HTTP_cookie" target="_blank"><strong>HTTP cookie</strong></a>.
                    </p>
                </div>
                <div class="col-12 col-md-2"></div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import headerRoutes from '../header-routes';
    import {mapGetters} from 'vuex';
    import {httpClient} from '../../../shared/services';
    import {Header, Footer} from '../../../shared/components';
    import {
        DxForm,
        DxSimpleItem,
        DxLabel,
        DxButtonItem,
        DxRequiredRule,
        DxEmailRule,
        DxPatternRule,
        DxColCountByScreen,
        DxCompareRule
    } from 'devextreme-vue/form';
    import DxButton from 'devextreme-vue/button';
    import CustomStore from 'devextreme/data/custom_store';

    export default {
        name: 'Registration',
        components: {
            Header,
            Footer,
            DxForm,
            DxSimpleItem,
            DxLabel,
            DxButton,
            DxButtonItem,
            DxRequiredRule,
            DxEmailRule,
            DxPatternRule,
            DxColCountByScreen,
            DxCompareRule
        },
        data() {
            return {
                headerRoutes,
                formData: {},
                labelLocation: 'top',
                errorMessage: "",
                storeСompanyBin: new CustomStore({
                    key: 'bin',
                    byKey: function (key) {
                        if(key != window.bin) {
                            window.bin = key;

                            return httpClient.get(`v2/Catalog/Companys?filter={"bin":{"op":"sw","value":"${key}"}}&order_field=bin&take=1`);
                        } else {
                            return {
                                "bin": window.bin,
                                "companyName": window.companyName
                            };
                        }
                    },
                    load: (loadOptions) => {
                        //console.log(loadOptions);

                        let searchValue = loadOptions.searchValue != null ? loadOptions.searchValue.trim() : '';

                        return httpClient.get(`v2/Catalog/Companys?filter={"bin":{"op":"sw","value":"${searchValue}"}}&order_field=bin&take=5`)
                            .then((data) => {
                                return {
                                    key: 'bin',
                                    data: data.data.items,
                                    totalCount: data.totals,
                                    summary: data.totals,
                                    groupCount: data.count,
                                    userData: data.data,
                                };
                            })
                            .catch(() => {
                                throw 'Data Loading Error';
                            });
                    }
                }),
                passwordOptions: {
                    mode: 'password'
                },
                checkBoxOptions: {
                    text: 'Я подтверждаю свое согласие с условиями',
                    value: false,
                    onContentReady: function (e) {
                        window.setTimeout(function() {
                            let checkboxText = e.element.getElementsByClassName("dx-checkbox-text");
                            checkboxText[0].innerHTML = 'Я подтверждаю свое согласие с условиями <a href="/agreements/user" target="_blank"><strong>Пользовательского соглашения</strong></a> и <a href="/agreements/privacy" target="_blank"><strong>Политики конфиденциальности</strong></a>';
                        }, 0);
                    }
                },
                buttonOptions: {
                    text: 'Зарегистрироваться',
                    type: 'default',
                    useSubmitBehavior: true
                },
                mobileEditorOptions: {
                    mask: '+X (000) 000 00 00',
                    maskRules: {
                        X: /[1]|[2]|[3]|[4]|[5]|[6]|[7]|[9]|[0]/
                    },
                    //useMaskedValue: true,
                    maskInvalidMessage: 'Номер не соответствует формату мобильного телефона.'
                },
                phoneEditorOptions: {
                    mask: '+X (000) 000 00 00',
                    maskRules: {
                        X: /[1]|[2]|[3]|[4]|[5]|[6]|[7]|[9]|[0]/
                    },
                    //useMaskedValue: true,
                    maskInvalidMessage: 'Номер не соответствует формату телефона.'
                },
                phonePattern: /^((1|2|3|4|5|6|7|9|0)\d{10})$/,
                buttonAgreementsPrivacyOptions: {
                    text: 'Политики конфиденциальности',
                    type: 'default',
                    stylingMode: 'text',
                    onClick: this.openPublicOffer
                },
                buttonAgreementsUserOptions: {
                    text: 'Пользовательского соглашения',
                    type: 'default',
                    stylingMode: 'text',
                    onClick: this.openPublicOffer
                },
                CompanyName: "",
                CompanyBin: ""
            }
        },
        mounted() {
            window.bin = undefined;
            window.companyName = undefined;
        },
        methods: {
            onSubmit: async function () {
                this.errorMessage = "";

                this.$store.dispatch('loading', true).then(() => {
                    const {
                        CompanyName,
                        CompanyBin,
                        AgentName,
                        Email,
                        Mobile,
                        Phone,
                        Login,
                        Password
                    } = this.formData;

                    console.log(CompanyName);
                    console.log(CompanyBin);

                    this.regAgent(AgentName, Email, Mobile, Phone, Login, Password, CompanyBin, CompanyName);
                });
            },
            regAgent(AgentName, Email, Mobile, Phone, Login, Password, CompanyBin, CompanyName) {

                if(Mobile) {
                    Mobile = `&mobile=${Mobile}`
                } else {
                    Mobile = '';
                }

                if(Phone) {
                    Phone = `&phone=${Phone}`
                } else {
                    Phone = '';
                }

                CompanyBin = this.$options.filters.encodeURL(CompanyBin);
                AgentName = this.$options.filters.encodeURL(AgentName);
                CompanyName = this.$options.filters.encodeURL(CompanyName);

                httpClient.post(`v2/Agent?companyBIN=${CompanyBin}&contactName=${AgentName}&companyName=${CompanyName}&email=${Email}${Mobile}${Phone}`, {},
                    {
                        headers: {
                            'Login': Login,
                            'Password': Password
                        }
                    }).then((response) => {
                    response.data['login'] = Login;
                    this.$store.dispatch('logIn', response.data).then(() => {
                        this.$store.dispatch('loading', false);
                        if(response.data.isVerified) {
                            this.$router.push(this.$route.query.redirect || '/');
                        } else {
                            this.openVerified();
                        }
                    });
                }).catch(this.handleError);
            },
            handleError: function (error) {
                if (error.response.status === 400) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessage = errorMessage.replace('Параметр "contactName", задан неверно! ', '');
                        this.errorMessage = this.errorMessage.replace('Параметр "companyBIN", задан неверно! ', '');
                        this.errorMessage = this.errorMessage.replace('Параметр "companyName", задан неверно! ', '');
                        this.errorMessage = this.errorMessage.replace('Параметр "email", задан неверно! ', '');
                        this.errorMessage = this.errorMessage.replace('Параметр "mobile", задан неверно! ', '');
                        this.errorMessage = this.errorMessage.replace('Параметр "phone", задан неверно! ', '');
                    } else {
                        this.errorMessage = "Ошибка при регистрации. Проверьте указанные вами данные.";
                    }
                } else {
                    this.errorMessage = "Ошибка при регистрации. Проверьте указанные вами данные.";
                }

                this.$store.dispatch('loading', false);
                console.error(error);
            },
            openVerified() {
                if (this.$route.query.redirect) {
                    this.$router.push({name: 'Verified', query: {redirect: this.$route.query.redirect}});
                } else {
                    this.$router.push({name: 'Verified'});
                }
            },
            openLogin() {
                if (this.$route.query.redirect) {
                    this.$router.push({name: 'Login', query: {redirect: this.$route.query.redirect}});
                } else {
                    this.$router.push({name: 'Login'});
                }
            },
            checkComparison() {
                return true;
            },
            openPublicOffer() {
                let routeData = this.$router.resolve({name: 'Dev'});
                window.open(routeData.href, '_blank');
            },
            companyBinChanged(e) {
                if(e.value) {
                    this.storeСompanyBin.byKey(e.value).then((company) => {
                        if(company.data && company.data.count == 1) {
                            this.formData.CompanyName = company.data.items[0].companyName;
                            this.CompanyName = company.data.items[0].companyName;
                            window.companyName = this.CompanyName;
                        } else if(company.bin && company.companyName) {
                            this.formData.CompanyName = company.companyName;
                            this.CompanyName = company.companyName;
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                } else {
                    this.formData.CompanyName = "";
                    this.CompanyName = "";
                    //window.companyName = undefined;
                }
            },
            addInFileKinds(obj) {
                if(!this.fileKinds.includes(obj)) {
                    this.fileKinds.push(obj);
                }
            },
            onCustomItemCreating(e) {
                this.formData.CompanyName = "";
                this.CompanyName = "";
                window.companyName = '';
                window.bin = e.text;

                return {
                    "bin": e.text,
                    "companyName": ''
                };
            },
            handleCompanyNameValueChange(e) {
                this.CompanyName = e.value;
                window.companyName = e.value;
            }
        },
        computed: {
            ...mapGetters([
                'isLoading'
            ])
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
